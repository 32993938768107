
<template>
    <div class="xSPACE">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/xSPACE/banner.png" alt="">
                <img class="bg2" src="@/assets/Home/Purpleblock.png" alt="">
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>{{xtokenSymbol}}</h1>
                    <h2>Governance Token. Convert {{tokenSymbol}} to {{xtokenSymbol}} for voting rights and  higher rewards.</h2>
                    <div>
                        <p class="rules" @click="messageBoxShow = true;messageType = 'rules'">Rules</p>
                        <a class="howtouse" href="https://docs.spacefi.io/tokenomics/xspace-token" target="_block">How to use</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="center connectBoxW">
                <div class="data">
                    <div class="myxspaceinfo">
                        <h1 class="centerTItle">{{xtokenSymbol}}</h1>
                        <ul>
                            <li>
                                <img src="@/assets/xSPACE/Total.png" alt="">
                                <!-- <p class="number">{{xSPACETotal}}</p> -->
                                <v-NumberGrow :valnumber="Number(xSPACETotal)"></v-NumberGrow>
                                <p class="p3">Total</p>
                            </li>
                            <li>
                                <img src="@/assets/xSPACE/Available.png" alt="">
                                <!-- <p class="number">{{xSPACEAvailable}}</p> -->
                                <v-NumberGrow :valnumber="Number(xSPACEAvailable)"></v-NumberGrow>
                                <p class="p3">Available</p>
                            </li>
                            <li>
                                <img src="@/assets/xSPACE/Staking.png" alt="">
                                <!-- <p class="number">{{xSPACEStakinginFarm}}</p> -->
                                <v-NumberGrow :valnumber="Number(xSPACEStakinginFarm)"></v-NumberGrow>
                                <p class="p3">Staking in Farm</p>
                            </li>
                            <li>
                                <img src="@/assets/xSPACE/Vesting.png" alt="">
                                <!-- <p class="number">{{xSPACEVesting}}</p> -->
                                <v-NumberGrow :valnumber="Number(xSPACEVesting)"></v-NumberGrow>
                                <p class="p3">Vesting</p>
                            </li>
                        </ul>
                    </div>
                    <div class="bonusforxspace">
                        <h1 class="centerTItle">Bonus for {{xtokenSymbol}}</h1>
                        <div class="box">
                            <div class="text">
                                <p>Claim the Bonus based on Your {{xtokenSymbol}} (except vesting)</p>
                                <p>Learn more about the Bonus for <span>bLP, Planet NFT, {{xtokenSymbol}}</span></p>
                            </div>
                            <router-link tag="div" class="btn" to='/#claim'>
                                Claim
                            </router-link>
                        </div>
                    </div>
                    <div class="getxspace">
                        <h1 class="centerTItle">GET {{xtokenSymbol}}</h1>
                        <div class="box">
                            <div class="ipt">
                                <div class="leftbox">
                                    <div>
                                        <span>Please Input :</span>
                                        <input type="number" v-model="getxspaceinput" name="" id="" placeholder="Please input">
                                    </div>
                                    <p class="maxbtn">
                                        <span @click="getxspaceinput = SPACEAvailable">MAX</span>
                                    </p>
                                </div>
                                <div class="rightbox">
                                    <p :class="getxspaceinput && userAddress ? '' : 'no'" @click="convert">Convert <v-LoadingWarp style="margin-left:3px;" v-if="convertLoading" :imgSize="1"></v-LoadingWarp></p>
                                </div>
                            </div>
                            <div class="text">
                                <div class="leftbox">
                                    <p>You will receive {{getxspaceinput ? getxspaceinput : 0}} {{xtokenSymbol}}</p>
                                    <p>Balance:{{SPACEAvailable}} {{tokenSymbol}}</p>
                                </div>
                                <div class="rightbox"></div>
                            </div>
                        </div>
                    </div>
                    <div class="vestingxspace">
                        <h1 class="centerTItle">VESTING</h1>
                        <p class="tips">1. Vest {{xtokenSymbol}} back into {{tokenSymbol}} over a duration of 20 days (50% back, 50% burn) to 180 days (100% back).</p>
                        <p class="tips">2. After 20 days, the remain 50% will be vested linearly until 180 days fully. The incompletely vested will also be burned.</p>
                        <div class="iptbox">
                            <div class="leftipt">
                                <p class="p1">
                                    <span>Vest {{xtokenSymbol}} :</span>
                                    <span>wallet balance : {{xSPACEBalanceof}} {{xtokenSymbol}}</span>
                                </p>
                                <div class="ipt">
                                    <div>
                                        <input type="number" v-model="vestxSPACEInput" @input="changexSPACE" name="" id="" placeholder="Please input">
                                        <p>
                                            <span @click="maxxSPACE">max</span>
                                        </p>
                                    </div>
                                    
                                </div>
                                <p class="p2">
                                    <span>Redeem ratio : {{ Redeemratio }} %</span>
                                    <span></span>
                                </p>
                            </div>
                            <div class="rightipt">
                                <p class="p1">
                                    <span>Vesting Days :</span>
                                    <span></span>
                                </p>
                                <div class="ipt">
                                    <div>
                                        <input type="number" v-model="vestingDaysInput" @input="changeDay" name="" id="" :placeholder="minvesting">
                                        <p>
                                            <span @click="maxDay">max</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p @click="minusDay"><i class="el-icon-minus"></i></p>
                                        <p @click="plusDay"><i class="el-icon-plus"></i></p>
                                    </div>
                                </div>
                                <p class="p2">
                                    <span>GRAIL output : {{GRAILoutput}} {{tokenSymbol}}</span>
                                    <span></span>
                                </p>
                            </div>
                        </div>
                        <div :class="vestxSPACEInput && userAddress ? 'vestbtn' : 'vestbtn no'" @click="vest">Vest <v-LoadingWarp style="margin-left:3px;" v-if="vestLoading" :imgSize="1"></v-LoadingWarp></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vestinglist">
            <div class="center connectBoxW">
                <div class="list">
                    <h1 class="centerTItle">VESTING</h1>
                    <ul>
                        <li v-if="vestinglist.length == 0" style="justify-content: center;">
                            No data available
                        </li>
                        <li v-else v-for="(item,index) in vestinglist" :key="index">
                            <div>
                                <p>{{xtokenSymbol}} input</p>
                                <p>{{item.xTokenAmount}}</p>
                                <p>{{xtokenSymbol}}</p>
                            </div>
                            <div>
                                <p>{{tokenSymbol}} output</p>
                                <p>{{item.TokenAmount}}</p>
                                <p>{{tokenSymbol}}</p>
                            </div>
                            <div>
                                <p>time left</p>
                                <p v-html="item.timeleft"></p>
                                <p class="opacity0">1</p>
                            </div>
                            <div>
                                <p>release</p>
                                <p>{{(item.release)}}%</p>
                                <p class="opacity0">1</p>
                            </div>
                            <div><p></p></div>
                            <div>
                                <p v-if="item.status == 1" class="claim" @click="claim(item)">claim <v-LoadingWarp style="margin-left:3px;" v-if="item.itemLoading" :imgSize="1"></v-LoadingWarp></p>
                                <p v-if="item.status == 0" :class="item.itemLoading ? 'claim' : 'cancle'" @click="cancle(item)">cancle <v-LoadingWarp style="margin-left:3px;" v-if="item.itemLoading" :imgSize="1"></v-LoadingWarp></p>
                            </div>
                        </li>
                    </ul>
                </div>
                <v-Footer></v-Footer>
            </div>
            
        </div>

        <div class="rulesmessageBox" v-if="messageBoxShow" @click="closemessage">
            <div class="connectBox connectBoxW">

                <div v-if="messageType == 'rules'" class="message0" @click.stop>
                    <h1>
                        Rules details
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="rules">
                        <p>1. xSPACE is a non-transferable escrowed governance token, which is converted by SPACE 1:1 locking with a 30 days unbonding period. If choosing to unbond xSPACE instantly, 50% SPACE will be converted back and the other 50% SPACE will be burned.</p>
                        <p>2. It can be earned from farming, or converting SPACE directly. xSPACE can be staked in farm to earn more xSPACE and be used in SpaceFi governance in the future.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Web3 from 'web3';
import {changeNumber, numberToFixed} from '@/utils/function.js'
import {
    judgeNetwork,setApprove,getAllowance,
    getBalanceOf,getxstarBalanceof,getfarmStakingBalancOf,getunbondingBalancOf,
    setconvert,
    getminRedeemDuration,getmaxRedeemDuration,getminRedeemRatio,getmaxRedeemRatio,getStarByVestingDuration,setredeem,
    getUserRedeemsLength,getuserRedeems,setfinalizeRedeem,setcancelRedeem,
} from '@/utils/space.js'
export default {
    name: "xSPACE",
    data() {
        return {
            userAddress: '',tokenSymbol: '',xtokenSymbol: '',
            SPACEBalanceof: 0,SPACEAvailable: 0,xSPACETotal: 0,xSPACEBalanceof: 0,xSPACEAvailable: 0,xSPACEStakinginFarm: 0,xSPACEVesting: 0,
            getxspaceinput: null,convertLoading: false,
            vestxSPACEInput: null,vestingDaysInput: null,Redeemratio: '---',GRAILoutput: '---',vestLoading: false,
            minvesting: '',maxvesting: '',minratio: '',maxratio: '',
            vestinglist:[],vestinglistBtnLoading:false,
            messageBoxShow:false,messageType:''
        }
    },
    created() { },
    mounted() {
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.farmAddress = window.contractAddr.farmAddress
        this.farmLibAddress = window.contractAddr.farmLibAddress
        this.farmPendingAddress = window.contractAddr.farmPendingAddress
        getminRedeemDuration(this.xTokenAddress,this.userAddress).then(res => {
            this.minvesting = res/60/60/24
            this.vestingDaysInput = this.minvesting
        }) 
        getmaxRedeemDuration(this.xTokenAddress,this.userAddress).then(res => {
            this.maxvesting = res/60/60/24
            //console.log(this.maxvesting)
        }) 
        getminRedeemRatio(this.xTokenAddress,this.userAddress).then(res => {
            this.minratio = res
            //console.log(this.minratio)
        })
        getmaxRedeemRatio(this.xTokenAddress,this.userAddress).then(res => {
            this.maxratio = res
            //console.log(this.maxratio)
        })
        if (this.userAddress) {
            this.getData()  
            this.getVESTINGRelease()
        }
    },
    methods: {
        closemessage(){
            this.messageBoxShow = false
            this.messageType = ''
        },
        convert(){
            if (Number(this.getxspaceinput) <= 0 ) {
                return false
            }
            if(this.convertLoading){
                    return false
            }
            if (this.userAddress == '') {
                this.$notify({type:'warning',message:'Please connect wallet first!'})
                return false
            }
            this.convertLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.convertLoading = false
                    return false
                }
                if (Number(this.getxspaceinput) > Number(this.SPACEAvailable)) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    this.convertLoading = false
                    return false
                }
                getAllowance(this.tokenAddress,this.xTokenAddress,this.userAddress).then(res => {
                    var allowanceNum = res/1e18
                    if (Number(this.getxspaceinput) > Number(allowanceNum) ) {
                        setApprove(this.tokenAddress,this.xTokenAddress,this.userAddress).then(res =>{
                            this.$message({ type: 'success', message: 'Approve Success' });
                            this.convertafter()
                        }).catch(err => {
                            this.convertLoading = false
                        })
                    } else {
                        this.convertafter()
                    }
                })
            })
        },
        convertafter(){
            var amount_ = this.$BigNumber(this.getxspaceinput).times(Math.pow(10,18))
            setconvert(this.xTokenAddress,amount_,this.userAddress).then(res => {
                this.getData()
                this.convertLoading = false
                this.getxspaceinput = null
            }).catch(err => {
                this.convertLoading = false
            })
        },

        changeDay(){
            this.vestingDaysInput = this.vestingDaysInput.replace(/[^\d]/g,'');
            this.getStarByVestingDuration()
        },
        maxDay(){
            this.vestingDaysInput = this.maxvesting
            this.getStarByVestingDuration()
        },
        minusDay(){
            if (this.vestingDaysInput <= this.minvesting) {
                this.$message({
                    type: 'warning',
                    message: 'Minimum days to Vesting '+this.minvesting+' days'
                })
            }
            if ( this.vestingDaysInput > this.minvesting ) { this.vestingDaysInput-- ; this.getStarByVestingDuration() }
        },
        plusDay(){
            if (this.vestingDaysInput >= this.maxvesting) {
                this.$message({
                    type: 'warning',
                    message: 'Maximum days to Vesting '+this.maxvesting+' days'
                })
                return false
            }
            if ( this.vestingDaysInput < this.maxvesting ) { this.vestingDaysInput++ ; this.getStarByVestingDuration() }
        },
        changexSPACE(){
            this.getStarByVestingDuration()
        },
        maxxSPACE(){
            this.vestxSPACEInput = this.xSPACEBalanceof
            this.getStarByVestingDuration()
        },
        getStarByVestingDuration(){
            if (this.vestxSPACEInput == '') {
                this.Redeemratio = '---'
                this.GRAILoutput = '---'
            }
            if (this.vestingDaysInput < this.minvesting) {
                this.Redeemratio = 50
                this.GRAILoutput = this.vestxSPACEInput/2
                return
            }
            if (this.vestingDaysInput > this.maxvesting) {
                this.Redeemratio = 100
                this.GRAILoutput = this.vestxSPACEInput
                return
            }
            if (this.vestxSPACEInput) {
                var _time = this.vestingDaysInput*60*60*24
                this.Redeemratio = ((0.5+(0.5/(this.maxvesting-this.minvesting)*(this.vestingDaysInput-this.minvesting)))*100).toString().match(/^\d+(?:\.\d{0,2})?/)[0]
                getStarByVestingDuration(this.xTokenAddress,this.$BigNumber(this.vestxSPACEInput).times(Math.pow(10,18)),_time,this.userAddress).then(res => {
                    //console.log(res)
                    if (this.vestxSPACEInput == '') {
                        this.Redeemratio = '---'
                        this.GRAILoutput = '---'
                    } else {
                        this.GRAILoutput = this.$BigNumber(res).div(Math.pow(10,18))
                    }
                })
            }
        },

        vest(){
            if (this.userAddress == '') {
                this.$notify({type:'warning',message:'Please connect wallet first!'})
                return false
            }
            // this.vestingDaysInput = this.vestingDaysInput > this.maxvesting ? this.maxvesting : this.vestingDaysInput < this.minvesting ? this.minvesting : this.vestingDaysInput;
            if (this.vestingDaysInput > this.maxvesting) {
                this.$message({
                    type: 'warning',
                    message: 'Maximum days to Vesting '+this.maxvesting+' days'
                })
                return false
            }
            if (this.vestingDaysInput < this.minvesting) {
                this.$message({
                    type: 'warning',
                    message: 'Minimum days to Vesting '+this.minvesting+' days'
                })
                return false
            }
            this.vestLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.vestLoading = false
                    return false
                }
                if (!this.vestxSPACEInput) {
                    this.$message({ type: 'warning', message: 'Please input' })
                    this.vestLoading = false
                    return false
                }
                if (this.vestxSPACEInput > this.xSPACEBalanceof) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    this.vestLoading = false
                    return false
                }
                getAllowance(this.tokenAddress,this.xTokenAddress,this.userAddress).then(res => {
                    var allowanceNum = res/1e18
                    if (Number(this.vestxSPACEInput) > Number(allowanceNum) ) {
                        setApprove(this.tokenAddress,this.xTokenAddress,this.userAddress).then(res =>{
                            this.$message({ type: 'success', message: 'Approve Success' });
                            this.vestafter()
                        }).catch(err => {
                            this.vestLoading = false
                        })
                    } else {
                        this.vestafter()
                    }
                })
            })
        },
        vestafter(){
            var amount_ = this.$BigNumber(this.vestxSPACEInput).times(Math.pow(10,18))
            var time_ = this.vestingDaysInput*60*60*24
            setredeem(this.xTokenAddress,amount_,time_,this.userAddress).then(res => {
                this.getData()
                this.vestLoading = false
                this.vestxSPACEInput = null
                this.vestingDaysInput = null
                this.Redeemratio = '---'
                this.GRAILoutput = '---'
            }).catch(err => {
                this.vestLoading = false
            })
        },
        
        claim(item){
            if ( this.vestinglistBtnLoading ) { return false }
            this.vestinglistBtnLoading = true
            item.itemLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.vestinglistBtnLoading = false
                    item.itemLoading = false
                    return false
                }
                setfinalizeRedeem(this.xTokenAddress,item.index,this.userAddress).then(res => {
                    this.getData()
                    this.vestinglistBtnLoading = false
                    item.itemLoading = false
                }).catch(err => {
                    //console.log(err)
                    this.vestinglistBtnLoading = false
                    item.itemLoading = false
                })
            })
        },
        cancle(item){
            if ( this.vestinglistBtnLoading ) { return false }
            this.vestinglistBtnLoading = true
            item.itemLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.vestinglistBtnLoading = false
                    item.itemLoading = false
                    return false
                }
                setcancelRedeem(this.xTokenAddress,item.index,this.userAddress).then(res => {
                    this.getData()
                    this.vestinglistBtnLoading = false
                    item.itemLoading = false
                }).catch(err => {
                    this.vestinglistBtnLoading = false
                    item.itemLoading = false
                })
            })
        },

        async getData(){
            getUserRedeemsLength(this.xTokenAddress,this.userAddress).then(vestingLenght => {
                //console.log(vestingLenght)
                this.vestinglist = []
                if (vestingLenght > 0) {
                    var _time = Math.floor( new Date().getTime()/1000 )
                    for (let i = 0; i < vestingLenght; i++) {
                        getuserRedeems(this.xTokenAddress,i,this.userAddress).then(res => {
                            //console.log(res)
                            //console.log(res.StarAmount,res.xStarAmount,_time,res.beginTime,res.endTime,res.beginTime)
                            var timeDifference = (res.endTime - res.beginTime)
                            var endTimeRelease = (_time - res.beginTime)
                            var timeRatio = endTimeRelease/timeDifference*100
                            res.release = Number(res.endTime) <= _time ? 100.00 : (res.StarAmount/res.xStarAmount*timeRatio).toString().match(/^\d+(?:\.\d{0,2})?/)[0]//((Number(res.TokenAmount)/Number(res.xTokenAmount))*100).toString().match(/^\d+(?:\.\d{0,2})?/)[0]
                            res.index  = i
                            res.xTokenAmount = numberToFixed(this.$BigNumber(res.xStarAmount).div(Math.pow(10,18)),6)
                            res.TokenAmount = numberToFixed(this.$BigNumber(res.StarAmount).div(Math.pow(10,18)),6)
                            res.timeleft = this.InitTime(Number(res.endTime)-_time)
                            res.status = Number(res.endTime) >= _time ? 0 : 1
                            res.itemLoading = false
                            this.vestinglist.push(res)

                            //console.log(this.vestinglist)
                            this.vestinglist.sort((a,b) => {
                                if (a.timeleft < b.timeleft) { return -1;
                                } else if (a.timeleft > b.timeleft) { return 1;
                                } else { return 0; }
                            })
                            this.$forceUpdate()
                            // if (this.vestinglist.length == Numres) {
                            //     this.vestinglistArr = this.fenge(this.vestinglist, this.pagesize)
                            //     this.vestinglistView = this.vestinglistArr[0]
                            //     this.$forceUpdate()
                            // }
                        }) 
                    }
                }
                
                
            }) 
            await getBalanceOf(this.tokenAddress,this.userAddress).then(res => {
                this.SPACEBalanceof = this.$BigNumber(res).div(Math.pow(10,18))
                this.SPACEAvailable = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)),8)
                // this.SPACEAvailable = this.$BigNumber(res).div(Math.pow(10,18))
            })
            await getxstarBalanceof(this.xTokenAddress,this.userAddress).then(res => {
                this.xSPACEBalanceof = this.$BigNumber(res).div(Math.pow(10,18))
                this.xSPACEAvailable = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)))
                // this.xSPACEAvailable = this.$BigNumber(res).div(Math.pow(10,18))
            })
            await getfarmStakingBalancOf(this.xTokenAddress,this.userAddress).then(res => {
                this.xSPACEStakinginFarm = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)))
            })
            await getunbondingBalancOf(this.xTokenAddress,this.userAddress).then(res => {
                this.xSPACEVesting = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)))
            })
            this.xSPACETotal = this.$BigNumber(Number(this.xSPACEAvailable)+Number(this.xSPACEStakinginFarm)+Number(this.xSPACEVesting)).toFixed(3)
        },
        getVESTINGRelease(){
            setInterval(() => {
                var _time = Math.floor( new Date().getTime()/1000 )
                this.vestinglist.forEach((item,index) => {
                    if (item.item != 100) {
                        getuserRedeems(this.xTokenAddress,item.index,this.userAddress).then(res => {
                            var timeDifference = (res.endTime - res.beginTime)
                            var endTimeRelease = (_time - res.beginTime)
                            var timeRatio = endTimeRelease/timeDifference*100
                            item.timeleft = this.InitTime(Number(res.endTime)-_time)
                            item.release = Number(res.endTime) <= _time ? 100.00 : (res.StarAmount/res.xStarAmount*timeRatio).toString().match(/^\d+(?:\.\d{0,2})?/)[0]//((Number(res.TokenAmount)/Number(res.xTokenAmount))*100).toString().match(/^\d+(?:\.\d{0,2})?/)[0]
                            item.status = Number(res.endTime) >= _time ? 0 : 1
                        })
                    }
                })
            }, 60000);
        },
        InitTime(time){ 
            var dd,hh,mm,ss = null;
            if(time<=0){
                return "<span>00D</span> 00h 00min 00s"
            }else{
                dd = Math.floor(time / 60 / 60 / 24);
                hh = Math.floor((time / 60 / 60) % 24);
                mm = Math.floor((time / 60) % 60);
                ss = Math.floor(time % 60);
                if (hh < 10 ){hh = "0" + hh ;}
                if (mm < 10 ){mm = "0" + mm ;}
                if (ss < 10 ){ss = "0" + ss ;}
                if(dd==0&&hh==0&&mm==0){
                    var str = "<span>00D</span> 00h 00min "+ss+"s"
                }
                else if(dd==0&&hh==0){
                    var str = "<span>00D</span> 00h "+mm + "min " +ss+"s"
                }
                else if(dd==0){
                    str =  "<span>00D</span> "+hh+"h "+mm+"min "+ss+"s"
                }else{
                    str = "<span>"+dd+"D</span> "+hh+"h "+mm+"min "+ss+"s";
                }
                return str;
            }
        },
    }
}
</script>